import React from 'react';
import { Container } from 'semantic-ui-react';
import styles from './styles';
import DynamicTitle from '../../components/common/DynamicTitle';

const termsOfUse = () => (
  <div style={styles.container}>
    <DynamicTitle title="Terms of Use" />
    <Container textAlign="center" style={styles.title}>
      <h2>TERMS OF USE</h2>
    </Container>
    <Container text textAlign="justified">
      <p>
        These Term of Use governs all bookings made by you (
        <strong>the “Agent”</strong>) with{' '}
        <strong>selected Tour Operator(s)</strong> available through{' '}
        <strong>touress (“touress or touress Global Holidays”) </strong>
        on http://www.touress.com and touress mobile applications (
        <strong>“the platform”</strong>).
      </p>
      <h3>1. Definitions </h3>
      <h4>
        <u>
          In this Term of Use the following words shall have the following
          meanings:
        </u>
      </h4>
      <p>
        <strong>“we”, “us” and “our”</strong> means{' '}
        <strong>touress web </strong>
        or <strong>touress mobile applications</strong>
      </p>
      <p>
        <strong>“you”, “yours” and “your”</strong> means
        <strong>the Agent</strong>
      </p>
      <p>
        <strong>“Tour Operator”</strong> means
        <strong>the Tour Operator</strong>
      </p>
      <p>
        <strong>“touress partners”</strong> means all partners registered in the
        platform including, but not limited to, Hotels/Accommodations, Airlines,
        Car/Bus Rentals, Cruises, Trains, Restaurants, Tour Guides, Attraction
        Providers, Insurance Companies, Internet Providers, and Visa Providers.
      </p>
      <p>
        <strong>“resources”</strong> means all resources managed by touress
        partners such as Hotels’ rooms and Airlines’ seats.
      </p>
      <p>
        <strong>“Booking”</strong> means order made by you with us using{' '}
        <strong>“the platform”</strong>{' '}
      </p>
      <h4>
        <u>
          The Bookings are processed by touress in accordance with these
          conditions:
        </u>
      </h4>
      <p>
        <strong>“Booking On Hold”</strong> means request made by you is still
        waiting for availability of one or more tour resources to be confirmed
        by touress partners
      </p>
      <p>
        <strong>“Booking Created”</strong> means request made by you has all
        required resources managed by touress partners available and blocked for
        a finite time period.
      </p>
      <p>
        <strong>“Booking Confirmation”</strong> means all required resources
        managed by touress partners have been allocated for you for a specific
        period as per Booking period.
      </p>
      <p>
        <strong>“Booking Cancelled”</strong> means all allocated resources
        blocked by touress partners are released due to no payment as specified
        by the deadline set.
      </p>
      <p>
        <strong>“Surcharge Periods”</strong> means periods specified by touress
        when religious events, cultural events, trade fairs, exhibitions, major
        conferences and other major events are being held at the relevant
        location.
      </p>
      <p>
        <strong>“Traveler”</strong> means each person traveling pursuant to a
        Booking.
      </p>
      <h3>2. Bookings</h3>
      <p>
        Any booking made shall be deemed to be an offer by you to purchase the
        relevant packages subject to booking conditions mentioned by touress in
        the platform.
      </p>
      <p>
        No Booking Form between you and us for packages shall come into
        existence until we convert your <strong>Booking Created</strong> status
        into <strong>Booking Confirmed</strong> status and (a) Booking Code is
        created; and (b) Booking Confirmation is sent by Email.
      </p>
      <p>All our advertised packages are subject to availability.</p>
      <p>
        When you confirm a Booking by changing the status from{' '}
        <strong>Booking Created</strong> to <strong>Booking Confirmed</strong>{' '}
        after payment, you are assumed to have read and accepted this term of
        use on behalf of your Traveler(s).
      </p>
      <p>
        It is your responsibility to ensure that any information for Traveler(s)
        which is given to you by touress or any of our employees or suppliers is
        passed on to all Traveler(s) for the relevant Booking. Any information
        that we give to you shall be deemed to be given to each and every
        Traveler travelling pursuant to a Booking.
      </p>
      <p>
        All Confirmed Booking processed on the platform will be
        <strong>
          <u>fully handled by Tour Operator</u>
        </strong>
        selected by you at the time of Booking.
      </p>
      <h3>3. Package Information </h3>
      <p>
        For a description of package detail please refer to the Booking Form on
        the platform.
      </p>
      <p>
        There are certain package conditions that are unique in selected
        destinations and they are highlighted in the Booking Form available on
        the platform.
      </p>
      <p>
        All details including Services provided, Rates, Incudes and Excludes are
        mentioned in detail on Booking Form available on the platform.
      </p>
      <h3>4. Product Information</h3>
      <p>
        Product information available on the platform is based on information
        collected from touress partners worldwide.
      </p>
      <p>
        Reasonable care has been taken that all the content available on the
        platform is correct but it is subject to amendment at any time without
        notice. All content on the platform is published in good faith, but you
        acknowledge that we, as booking agent, cannot check the accuracy of all
        information provided by touress partners.
      </p>
      <h3>5. Resource Star Ratings</h3>
      <p>
        Star ratings are used to symbolize the overall quality, level of
        service, food standard and range of resources available in any given
        package’s components including but not limited to accommodation,
        vehicles, restaurants, and attractions.
      </p>
      <p>
        The criteria applied within each country will vary depending on the
        specific requirements established by the relevant issuing body where
        such a body exists and some countries do not use star ratings for
        official categorization.
      </p>
      <p>
        Descriptions of resources on the platform shall be referred to for
        information purposes only and are not a guarantee or warranty of any
        kind by touress.
      </p>

      <h3>6. Cancellations and Amendments</h3>
      <p>
        Cancellation charges will be applied in relation to the notice period
        between notification of cancellation and the date of stay at each
        resource or the date of use at each resource within a Booking.
      </p>
      <p>
        The detail term of cancellations or amendments are available in each
        Booking on the platform. There will be no cancellation charges if the
        status of the booking is not yet Confirmed.
      </p>

      <h3>7. Packages</h3>
      <p>
        Any amendment to any resource part of a package booking after it has
        been Confirmed will result in that package booking becoming unpackaged,
        and any remaining individual component will subsequently become an
        individual Booking. If any package Booking amendment results in a
        variation to the price originally invoiced, a new invoice will be issued
        for each new Booking.
      </p>

      <h3>8. Delivery of the Services</h3>
      <h4>
        <u>Selected Tour Operator</u>
      </h4>
      <p>
        All Confirmed Booking processed on the platform will be fully handled by
        Tour Operator selected by you at the time of Booking.
      </p>
      <h4>
        <u>Accommodations or Hotels</u>
      </h4>
      <p>
        Personal expenses such as porterage services, drinks from the mini bar,
        meals, tips, dry cleaning, laundry, telephone calls are not included in
        the prices and shall be paid directly at the accommodation or to
        suppliers by the Traveler(s).
      </p>
      <p>
        The total number of Traveler (including infants and children) must not
        exceed the maximum occupancy shown on the platform or on our Booking
        Confirmation. If the traveler(s) is/are not compliance of this rule, the
        accommodations or hotels management may refuse the additional
        traveler(s) to be accommodated or additional charges will be levied
        directly to the Traveler(s).{' '}
      </p>
      <p>
        For certain accommodation, there is a condition that the accommodation
        requires a deposit paid directly by the Traveler(s) on arrival, which
        will normally be applied on a credit card or cash, to cover any
        supplementary charges incurred.
      </p>
      <p>
        The platform may contain layout of the accommodations, photos of the
        rooms and other areas of the accommodations. If so, this is based on
        specifications provided to us by touress accommodations and hotels’
        partners. The photos will only be a general representation of the
        accommodations or hotels. Actual unit size, design, fixtures,
        furnishings and facilities may vary from those shown on the photos.
      </p>
      <p>
        During Traveler(s)’ stay period, Traveler(s) is/are responsible for any
        damage caused by each Traveler within its party.
      </p>
      <p>
        Each Traveler must comply with any rules and regulations set by the
        Accommodations or Hotels for the relevant Booking, including but not
        limited to, check-in and check-out timings, breakfast timing, use of
        facilities, and parking rules.{' '}
      </p>
      <h4>
        <u>Tips, Porterage, and Luggage</u>
      </h4>
      <p>
        Tips and Porterage are not included and these are at the discretion of
        the Traveler(s).
      </p>
      <p>
        For services where Traveler(s) have luggage with them, it is the
        Traveler(s) responsibility to ensure that a large enough vehicle is
        booked to accommodate all luggage. If Traveler(s) bring extra luggage
        that cannot fit in the vehicle, the Traveler(s) will need to cover any
        additional costs incurred in transferring their luggage.
      </p>
      <p>
        Unless porterage services are included in the booking as shown on
        Booking Form, it is traveler(s) duty to bring their own luggage.
        Driver(s), Tour Guide(s), or any other local representative(s) is/are
        not responsible for the traveler(s)’ luggage.
      </p>
      <h4>
        <u>Children Traveler</u>
      </h4>
      <p>
        Children or Infant travelling free or sharing bed with parents may not
        be entitled to meals and must be carried on the lap of accompanying
        adults if no seats are available.
      </p>
      <p>
        Children travelling with extra bed service will be considered as adult
        and will receive all adults’ benefits.
      </p>
      <h4>
        <u>Excursions or Sightseeing Tours</u>
      </h4>
      <p>
        The selected Tour Operator who manage excursions or sightseeing tours
        reserve the right to ask any traveler(s) to withdraw from any excursions
        or sightseeing tours if they deem their acts or conduct offensive or a
        nuisance to other traveler(s) and there shall be no further liability to
        the selected Tour Operator.
      </p>
      <p>
        The selected Tour Operator who manage excursions or sightseeing tours
        decline any responsibility for articles forgotten or lost in their
        resources.
      </p>
      <p>
        Pick up service from hotels and accommodations, if requested as part of
        the excursion or sightseeing tours and shown on Booking Form, is
        provided.
      </p>
      <p>
        Pick up times from accommodation and duration of all excursion or
        sightseeing tours are deemed to be followed by both traveler(s) and the
        selected Tour Operator. In some cities, where applicable, some
        adjustments are required due to traffic conditions.
      </p>
      <p>
        Where pick-up to and from hotels or accommodations are included in the
        excursion or sightseeing tours, the selected Tour Operator will pick up
        from the locations where pick-up is allowed. If the selected Tour
        Operator is unable to pick-up from the normal pick-up location, an
        alternative pick-up point will be suggested.
      </p>
      <p>
        Excursions or sightseeing tours with driver and tour guide, or driver as
        tour guide, include any entrance fees unless it is mentioned otherwise.
        Local tour guide enters many attractions free of charge, but where
        Traveler(s) require their guide to accompany them into an attraction,
        they may be asked to cover the guide’s entrance fee.
      </p>
      <p>
        Where available, excursions or sightseeing tours may be conducted with a
        driver providing the commentary whilst driving. Traveler(s) should note
        that although drivers are able to give a commentary while driving, they
        may not be able to park the vehicle and accompany Traveler(s) inside
        specific attractions and they may not be permitted to conduct tours on
        foot, due to guiding regulations. This option
      </p>
      <p>Traveler(s) looking for a panoramic city tour.</p>
      <p>
        Excursions or sightseeing tours should be conducted within city limits
        unless it is mentioned otherwise.
      </p>
      <p>
        For excursions or sightseeing tours, Traveler(s) can expect their tour
        guide to have a good overall knowledge of the city sights but if they
        require exceptionally in-depth commentary on a particular attraction,
        this may not be possible.
      </p>
      <h4>
        <u>Transfers</u>
      </h4>
      <p>
        For services starting at airports, stations or ports, it is the
        Traveler’s responsibility to ensure that he/she waits for his/her driver
        or representative as assigned by the selected Tour Operator at the
        appointed meeting point as detailed on the Booking Form or as detailed
        by the selected Tour Operator.
      </p>
      <p>
        Should the Traveler not be able to locate his/her driver or
        representative at the start of a service for which a booking has been
        made, it is the Traveler’s responsibility to call the relevant telephone
        number shown on the Booking Form provided to the Traveler on the
        platform or as informed by the selected Tour Operator.
      </p>
      <p>
        For services starting at accommodation, Traveler(s) should ensure that
        they are waiting at the appointed meeting point, as shown on the Booking
        Form or as informed by the selected Tour Operator, not less than 5
        minutes prior to the confirmed pick up time.
      </p>
      <p>
        For transfer services where a tour guide is included in the service, and
        if the tour guide is allowed by the relevant authority, he/she will
        assist with check-in as necessary at the Traveler’s accommodation,
        airport, port or station.
      </p>
      <p>
        For transfers that include the services of a driver, the driver will not
        assist with check-in at the accommodation or at the airport, port or
        station unless specifically stated in the extended description.
      </p>
      <p>
        For transfer services where a tour guide is available at the airport
        only, the tour guide will escort the Traveler to their waiting driver
        who will carry out the rest of the service in the booked vehicle as
        shown on the Booking Form or as informed by the selected Tour Operator.
      </p>
      <p>
        The durations of all transfers follow normal waiting time in average
        conditions. We take no responsibility for a flight, train or other
        connection being missed which is out of selected Tour Operator’s
        control. In a circumstance where traveler(s) notice the delay or change
        of itinerary time and inform the selected Tour Operator, the selected
        Tour Operator will try at their maximum capacity to accommodate the
        change and additional charges may be charged directly to traveler(s).
      </p>

      <h3>9. Cancellations due to Force Majeure</h3>
      <p>
        The selected Tour Operator may in its absolute discretion cancel or
        reschedule all or some of the agreed services including but not limited
        to accommodations, transfers, excursions or sightseeing tours, meals,
        and any other services from touress partners, if at any time either
        prior to or during the service period by reason of the occurrence of a
        fire, flood, earthquake, storm, lighting, explosion, fog or bad weather,
        interruption or failure of a utility service (including but not limited
        to electricity, gas, water, and telecommunication), renovations
        undertaken by accommodations, strikes, interruption of transport,
        embargo, industrial dispute, riots, civil unrest, act of terrorism,
        military coup or change of government, health risk or epidemic, or any
        other act or event occurring in the region of the country or countries
        on the itinerary of the tour and the selected Tour Operator may in its
        absolute discretion decides constitutes a danger to the security or
        well-being of Traveler(s).
      </p>
      <p>
        In the event of such cancellation, the selected Tour Operator shall not
        be responsible for the refund of any or other payments made by
        Traveler(s) and you shall have no right to claim damages from the
        selected Tour Operator.{' '}
      </p>

      <h3>10. Complaint</h3>
      <p>
        Any complaint regarding the supply of a service should be brought to the
        attention of the selected Tour Operator as early as possible during the
        Traveler’s stay in order for the complaint to be dealt with quickly.
        However, if not resolved to your satisfaction it should be notified to
        us in writing by email within 1 month of the date of service. Any
        complaint received after 1 month of the date of service will not be
        investigated.
      </p>

      <h3>11. Liability</h3>
      <p>
        Although we take reasonable care to ensure that the descriptions on the
        platform are correct we do not own or operate hotels or other
        accommodation or travel services.
      </p>
      <p>
        We accept no liability for errors or omissions in the description of
        accommodations or other resources available on the platform.
      </p>
      <p>
        We reserve the right to change your accommodations or hotels or any
        other services booked on the platform to one of at least comparable
        standard and to notify you of such change as soon as possible after it
        occurs. No compensation is payable for such changes.
      </p>
      <p>
        In extreme circumstances, we may be forced to cancel your Booking in
        which case you will be notified of such a change as soon as possible and
        a full and prompt refund will be made.
      </p>
      <p>
        We are not liable for personal injury, illness, property damage or any
        other loss (direct or indirect) or expense arising out of actions of
        touress partners including but not limited to accommodations, hotels,
        transportation companies or other parnters providing services reserved
        through us unless such loss or expense is due to our negligence or
        default. In no circumstances, shall we be liable for any consequential
        and indirect loss or damage. Our liability in respect of any Booking
        shall be limited to the total amount paid by you to us for such Booking.
        The foregoing shall not limit our liability for death or personal injury
        caused by our negligence.
      </p>

      <h3>12. General</h3>
      <p>
        We reserve the right to change these terms of use from time to time.
      </p>
      <p>
        The Booking Confirmation, the Booking Form and these term of use
        represent our entire agreement and as a result, you have not entered
        into a contract with us on the basis of any representation not expressly
        incorporated into these documents.
      </p>
      <p>
        If any of these conditions shall be illegal or unenforceable such term
        shall not form part of the conditions but the validity and
        enforceability of the other conditions shall not be affected.
      </p>
      <p>
        The laws of Singapore shall apply to these conditions and shall be
        subject to the exclusive jurisdiction of the Singapore courts.
      </p>
    </Container>
  </div>
);

export default termsOfUse;
