import React from 'react';
import { Container } from 'semantic-ui-react';
import styles from './styles';
import DynamicTitle from '../../components/common/DynamicTitle';

const privacyPolicy = () => (
  <div style={styles.container}>
    <DynamicTitle title="Privacy Policy" />
    <Container textAlign="center" style={styles.title}>
      <h2>PRIVACY POLICY</h2>
    </Container>
    <Container text textAlign="justified">
      <h4>
        The following discloses our information &quot;gathering&quot; and
        dissemination practices for this web site.
      </h4>
      <p>
        <strong>Information collection and use</strong>
      </p>
      <p>
        <u>This site is operated by touress Global Holidays</u>
      </p>
      <p>a) The name of your travel agency</p>
      <p>b) Licence number</p>
      <p>c) Email address</p>
      <p>d) Postal address</p>
      <p>e) Telephone number</p>
      <p>f) Credit/debit card number and expiry date</p>
      <p>g) Passenger names</p>
      <p>
        We use the contact information in order to reserve the required
        packages, to send you details of that confirmation and if necessary to
        inform you of updates or changes to your passenger&apos;s travel plans.
        If we have trouble processing a request this contact information is used
        to get in touch with you. We would use your telephone number only if we
        needed to contact you urgently or if you had made a mistake entering
        your details. The financial information is used for billing purposes and
        to complete your orders.
      </p>
      <p>
        We may also use your contact details to inform you of our products
        including special offers that we think may be of interest to you. If you
        do not want us to use your contact information to send details of our
        products to you please let us know by sending us an email (please see
        contact us for email details). We do not pass details of your personal
        information to third parties except as described in Sharing Information
        below.
      </p>
      <p>
        We store your information for as long as we need to do so in order to
        keep you informed of our products. We use your information to create
        generic information, which we then use for market and commercial
        analysis. This generic information does not identify you personally.
      </p>
      <p>
        <strong>Sharing Information</strong>
      </p>
      <p>
        Information is shared with a) our bank and b) involved third parties.
      </p>
      <p>
        a) When paying by credit or debit card we pass card details to our bank
        for authorisation of the transaction and for payment.
      </p>
      <p>
        b) We may disclose your information to accommodations, restaurants,
        vehicles, attraction providers, and other suppliers who will use your
        information in the ways described above in this privacy statement. We
        will provide only the information necessary to ensure the successful
        fulfillment of your passengers&apos; travel arrangements.
      </p>
      <p>
        c) Apart from sharing your information as mentioned above, we do not
        disclose your details to any other person without your consent unless
        there is an emergency that requires it or we are legally required to do
        so.
      </p>
      <br />
      <p>
        <strong>Log Files</strong>
      </p>
      <p>
        We use IP addresses to help diagnose problems with our server and to
        analyse trends, administer the site and track user&apos;s movement for
        our internal site traffic statistics.
      </p>
      <br />
      <p>
        <strong>Cookies</strong>
      </p>
      <p>
        We use cookies to monitor which parts of the site are most interesting
        to you - a cookie is a piece of data stored on your hard drive and usage
        is in no way linked to any personally identifiable information whilst on
        our site. You can always choose to not receive a cookie file by enabling
        your Web browser to refuse cookies or prompt before accepting a cookie
        but by refusing to accept a Cookie you may not be able to access many of
        the travel services we offer.
      </p>
      <br />
      <p>
        <strong>International Data Transfer</strong>
      </p>
      <p>
        Your communications with us may result in the transfer of information
        across international boundaries. By communicating with us you consent to
        this.
      </p>
      <br />
      <p>
        <strong>Links</strong>
      </p>
      <p>
        This site contains links to other web sites. Please be aware that we are
        not responsible for the privacy practices of such other web sites - this
        privacy statement applies solely to information collected by this web
        site.
      </p>
      <br />
      <p>
        <strong>Security</strong>
      </p>
      <p>
        This site has security measures in place to protect the loss, misuse and
        alteration of the information under our control. We use Secure Socket
        Layers (SSL) to ensure the privacy of the information provided to us and
        at no time are credit card details transmitted unencrypted over the
        Internet. To protect further the information you share with us we have
        an exhaustive security system that includes world class firewall
        technologies; logical network segmentation to allow restricted access to
        stored profile information and logical and physical security
        restrictions to our databases and systems. Whilst SSL encryption is used
        to protect sensitive information online we also do everything in our
        power to protect user-information off-line. All your information is
        restricted in our offices and only employees who need the information to
        perform a specific job are granted access to personally identifiable
        information. All employees are kept up-to-date on our security and
        privacy practices with regular quarterly updates or whenever new
        policies are added.
      </p>
      <br />
      <p>
        <strong>Changes to our Privacy Statement</strong>
      </p>
      <p>
        Any material changes in the way we use personal information will be
        described in future versions of this privacy statement. By booking with
        us you agree that our privacy statement, current at the time of your
        most recent booking, shall apply to all your information, whether given
        to us at the time of that booking or at the time of earlier bookings. If
        at any time we decide to use personally identifiable information in a
        manner different from that stated in the privacy statement applicable to
        your information, we will notify you by way of an email and request your
        approval.
      </p>
      <br />
      <p>
        <strong>Contacting us</strong>
      </p>
      <p>
        In using this web site, you consent to the collection and use of this
        information in the ways described above. If you have any questions or
        comments about this privacy statement, the practices of this site or
        your dealings with the site please send us an email (please see contact
        us for email details).
      </p>
    </Container>
  </div>
);

export default privacyPolicy;
