import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Container, Segment, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import {
  googlePlayBadge,
  appStoreBadgeBlack,
} from './../../../../assets/apps badges/';
import styles from './styles';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const Footer = () => {
  const { t, i18n } = useTranslation();

  if (i18next.languages[0] !== localStorage.getItem('language')) {
    i18n.changeLanguage(localStorage.getItem('language'));
  }

  return (
    <Segment inverted vertical style={styles.container}>
      <Container
        text={true}
        className="container-fit padding-side-twentyfive"
        style={styles.footerMenu}
      >
        <Grid columns="equal" style={styles.footerMenu}>
          <Grid.Row colums={6}>
            <Grid.Column />
            <Grid.Column>
              <div style={styles.columnMenu}>
                {/* Help */}
                <strong>{t('help')}</strong>
              </div>
              <div style={styles.marginBottom10px}>
                <Link to="/privacy-policy" style={styles.link}>
                  {/* Privacy Policy */}
                  {t('privacyPolicy')}
                </Link>
              </div>
              <Link to="/terms-of-use" style={styles.link}>
                {/* Terms of Use */}
                {t('termsOfUse')}
              </Link>
            </Grid.Column>
            <Grid.Column>
              <div style={styles.columnMenu}>
                {/* Company */}
                <strong>{t('company')}</strong>
              </div>
              <div style={styles.marginBottom10px}>
                <a
                  href="http://web.touress.com"
                  target="_blank"
                  style={styles.link}
                  rel="noopener noreferrer"
                >
                  {/* Corporate Website */}
                  {t('corporateWebsite')}
                </a>
              </div>
              <div style={styles.marginBottom10px}>
                <Link to="/about-us" style={styles.link}>
                  {/* About Us */}
                  {t('aboutUs')}
                </Link>
              </div>
              <Link to="/contact-us" style={styles.link}>
                {/* Contact Us */}
                {t('contactUs')}
              </Link>
            </Grid.Column>
            <Grid.Column>
              <div style={styles.columnMenu}>
                {/* Partnership */}
                <strong>{t('partnership')}</strong>
              </div>
              <div style={styles.marginBottom10px}>
                <Link to="/supplier-sign-in" style={styles.link}>
                  {/* Supplier Sign-in */}
                  {t('supplierSignIn')}
                </Link>
              </div>
              <Link to="/partners" style={styles.link}>
                {/* Partners */}
                {t('partners')}
              </Link>
            </Grid.Column>
            <Grid.Column>
              <div style={styles.columnMenu}>
                {/* Download App */}
                <strong>{t('downloadApp')}</strong>
              </div>
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.connectworld.touress"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Image
                    src={googlePlayBadge}
                    style={styles.marginGooglePlay}
                    fluid
                  />
                </a>
              </div>
              <div>
                <a
                  href="https://itunes.apple.com/id/app/touress-com/id1453939877?mt=8%E2%80%8B"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Image
                    src={appStoreBadgeBlack}
                    style={styles.marginAppStore}
                    fluid
                  />
                </a>
              </div>
            </Grid.Column>
            <Grid.Column />
          </Grid.Row>
          <Grid.Row style={styles.copyright} textAlign="center">
            <Grid.Column>
              <strong>
                {/* Copyright &copy; 2018 touress Global Holidays Version 2.3.31 */}
                {t('copyright')}
              </strong>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
};

Footer.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.object,
};

export default Footer;
