import React from 'react';
import { Grid, Container, Embed } from 'semantic-ui-react';
import { video } from '../../../../assets/logo';
import styles from './styles';
import PropTypes from 'prop-types';
import Slider from './SlideChangeHooks';
import { useTranslation } from 'react-i18next';

const linkYoutube = [
  'BtSSu21ycgA',
  'I3CZRrf1ZrA',
  '2cDQyrB7gXQ',
  '9Mb3ULjyhMk',
];
const HowItWorks = props => {
  const { t } = useTranslation();
  return (
    <Container className="margin-bottom-fifteen margin-top-ten padding-side-twentyfive container-fit">
      <Grid centered>
        <Grid.Row>
          <Grid.Column width={16}>
            {/* How It Works */}
            <h1 style={styles.textCenter}> {t('howItWorks')}</h1>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {props.token ? (
            <Grid.Column width={10}>
              <Embed
                autoplay={false}
                brandedUI
                color="white"
                hd={false}
                id={'VINGYD7rqTc'}
                placeholder={video}
                source="youtube"
                style={styles.videoStyle}
              />
            </Grid.Column>
          ) : (
            <Slider width={10}>
              {linkYoutube.map(e => (
                <Embed
                  key={e}
                  autoplay={false}
                  brandedUI
                  color="white"
                  hd={false}
                  id={e}
                  placeholder={video}
                  source="youtube"
                  style={styles.videoStyle}
                />
              ))}
            </Slider>
          )}
        </Grid.Row>
      </Grid>
    </Container>
  );
};

HowItWorks.propTypes = {
  token: PropTypes.string,
  t: PropTypes.func,
};
export default HowItWorks;
