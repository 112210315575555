import React from 'react';
import { Container } from 'semantic-ui-react';
import styles from './styles';
import DynamicTitle from '../../components/common/DynamicTitle';

const aboutUs = () => (
  <div>
    <DynamicTitle title="About Us" />
    <Container textAlign="center" style={styles.title}>
      <h2>ABOUT US</h2>
    </Container>
    <Container text textAlign="justified">
      <p style={styles.aboutUsComponent}>
        touress is truly a Global Holidays company which manages most
        sought-destinations around the World especially in the Middle East,
        Russia, CIS, Europe, and Indonesia.
      </p>
      <p style={styles.aboutUsComponent}>
        Our operations and contracting offices are located in Berlin (Germany),
        Istanbul (Turkey), Dubai (UAE), Tashkent (Uzbekistan), Jakarta
        (Indonesia), and Bali (Indonesia) giving our customers, complete peace
        of mind and best prices possible.
      </p>
      <p style={styles.aboutUsComponent2}>
        touress Global Holidays has so far served more than 36,000 customers
        worldwide and issued more than 4000 bookings in the last 12 years of its
        operations.
      </p>
    </Container>
  </div>
);

export default aboutUs;
