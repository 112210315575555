const styles = {
  title: {
    marginTop: '30px',
    marginBottom: '30px',
  },
  container: {
    marginBottom: '30px',
  },
  paddingBottomZeroNoBorder: {
    paddingBottom: 0,
    border: 'none',
  },
  aboutUsComponent: { lineHeight: '30px', marginBottom: '10px' },
  aboutUsComponent2: { lineHeight: '30px', marginBottom: '50px' },
  marginBot50: { marginBottom: '50px' },
  width100height300: { width: '100%', height: '300px' },
  marginLeft6: { marginLeft: '6px' },
};

export default styles;
